import { AnimateSharedLayout } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

export const Graph = ({ data, i, color }) => {
  const options = {
    plugins: {
      title: {
        display: false,
        text: 'FUNDS',
      },
      legend: {
        display: false,
        position: 'top',
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          color: (color && '#ffffff') || '#0C5964',
        },
        grid: {
          color:
            (color && 'rgba(255, 255, 255, 0.2)') || 'rgba(12, 89, 100, 0.2)',
          borderColor:
            (color && 'rgba(255, 255, 255, 0)') || 'rgba(12, 89, 100, 0.2)',
        },
      },
      y: {
        ticks: {
          color: (color && '#ffffff') || '#0C5964',
        },
        grid: {
          color:
            (color && 'rgba(255, 255, 255, 0.2)') || 'rgba(12, 89, 100, 0.2)',
          borderColor:
            (color && 'rgba(255, 255, 255, 0)') || 'rgba(12, 89, 100, 0.2)',
        },
      },
    },
  };

  const [res, setRes] = useState(data.data.length - 1);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const activeData = data.data;
  const [fundReturn, setFundReturn] = useState();
  const [benchmark, setBenchmark] = useState();

  useEffect(() => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const labels = activeData.map((item) => {
      const date = new Date(item.Date);
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      if (month === 'Jan') {
        return `${month} ${year}`;
      }
      return month;
    });

    const values = activeData.map((item) =>
      i === 0
        ? item['Ranger Fund']
        : i === 1
        ? item['5 Oceans Fund']
        : item['Trans-Tasman']
    );
    const ocrValues = activeData.map((item) =>
      i === 0
        ? item['OCR + 5%']
        : i === 1
        ? item['OCR + 3%']
        : item['S&P/NZX 50 Index']
    );

    const totalReturn =
      Math.round(
        ((values[values.length - 1] - values[0]) / values[0]) * 100 * 100
      ) / 100;
    const benchmark =
      Math.round(
        ((ocrValues[ocrValues.length - 1] - ocrValues[0]) / ocrValues[0]) *
          100 *
          100
      ) / 100;

    setFundReturn(totalReturn);
    setBenchmark(benchmark);

    setChartData({
      labels: labels,
      datasets: [
        {
          label: 'Fund Return: ' + totalReturn + '%',
          data: values,
          borderColor: `${(color && '#ffffff') || '#1DACB5'}`,
          color: `${(color && '#ffffff') || '#1DACB5'}`,
          backgroundColor: `${(color && '#ffffff') || '#1DACB5'}`,
        },
        {
          label: 'Benchmark: ' + benchmark + '%',
          data: ocrValues,
          borderColor: `${(color && '#0C5964') || '#0C5964'}`,
          color: `${(color && '#0C5964') || '#0C5964'}`,
          backgroundColor: `${(color && '#0C5964') || '#0C5964'}`,
        },
      ],
    });
  }, [res]);

  /* Remove until further notice */
  // const graphYears = [
  //   { years: '1 Y', months: 13 },
  //   { years: '2 Y', months: 25 },
  //   { years: '3 Y', months: 37 },
  //   { years: '5 Y', months: 61 },
  //   {
  //     years: 'Total',
  //     months: data.data.length - 1,
  //   },
  // ];

  //Set 16 pixels per data point for dynamic width;
  const totalDataPoints = chartData.labels.length;
  const dynamicWidth = totalDataPoints * 16;

  return (
    <>
      <div
        className={`col-start-1 col-end-15 lg:col-start-2 lg:col-end-14 flex justify-between border-b mr-4 ${
          (color && 'border-white') || 'border-dark-navy'
        }`}
      >
        <AnimateSharedLayout>
          {/* Remove until further notice */}
          {/* <nav className={`flex flex-row gap-10`}>
            {graphYears.map((year, i) => {
              return (
                <div
                  key={i}
                  className={`w-16 relative text-center ${
                    (color && color) || 'text-dark-navy'
                  }`}
                >
                  <button
                    className='w-full focus:outline-none pb-2'
                    onClick={() => {
                      setRes(year.months);
                      setActiveTab(i);
                    }}
                  >
                    {year.years}
                  </button>
                  {activeTab === i && (
                    <motion.span
                      layoutId='line'
                      className={`w-3/4 lg:w-full h-1 block absolute ${
                        (color && 'bg-white') || 'bg-dark-navy'
                      }`}
                      style={{ bottom: '-2px' }}
                    />
                  )}
                </div>
              );
            })}
          </nav> */}
          <nav className={`flex flex-row gap-10`}>
            <div
              className={`w-16 relative text-center focus:outline-none pb-2 ${
                (color && color) || 'text-dark-navy'
              }`}
            >
              Total
              <span
                layoutId='line'
                className={`w-3/4 lg:w-full h-1 block absolute ${
                  (color && 'bg-white') || 'bg-dark-navy'
                }`}
                style={{ bottom: '-2px' }}
              />
            </div>
          </nav>
        </AnimateSharedLayout>
        {/* Remove until further notice */}
        {/* <div
          className={` ${
            (color && color) || 'text-dark-navy'
          } flex flex-row font-bold items-center pb-2 gap-x-4`}
        >
          <span
            style={{ height: '0.175rem' }}
            className={`${(color && 'bg-white') || 'bg-teal'} w-7`}
          />
          <div className='mr-4'>{'Fund Return: ' + fundReturn + '%'}</div>

          <span style={{ height: '0.175rem' }} className='bg-dark-navy w-7' />
          <div className='mr-4'>{'Benchmark: ' + benchmark + '%'}</div>
        </div> */}
      </div>
      <div className='col-start-2 col-end-14 lg:col-start-3 lg:col-end-13 mt-4 overflow-x-scroll'>
        <div
          style={{
            minWidth: `${dynamicWidth}px`,
            width: '100%',
          }}
        >
          <Line
            data={chartData}
            options={options}
            style={{ maxHeight: '400px', width: '100%' }}
          />
        </div>
      </div>
    </>
  );
};
