import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "gatsby";
import React from "react";
import { useScrollFreeze } from "../../hooks";
import { ExternalLink } from "../ui";

const navVars = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};

const NavInner = ({ ...props }) => {
  const leftLinks = props.links[0];
  const rightLinks = props.links[1];

  useScrollFreeze();
  return (
    <motion.div
      variants={navVars}
      initial="closed"
      animate="open"
      exit="closed"
      className="w-full h-full bg-dark-navy fixed top-0 z-40 flex items-center text-center md:text-left"
      {...props}
    >
      <div className="grid grid-cols-14 items-center w-full gap-y-8">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.3 },
          }}
          className="col-start-2 md:col-start-3 col-end-14 md:col-end-8 flex flex-col h-full justify-between"
        >
          {leftLinks.map(({ linkText, page, path }, i) => {
            const pathLink =
              (page && `/${page.slug.current}`) || (path && `/${path}`);
            return (
              <Link
                key={i}
                to={pathLink}
                className={cx(
                  "text-white text-3xl md:text-5xl block leading-none",
                  {
                    "mb-4 md:mb-8": i !== leftLinks.length - 1,
                  }
                )}
              >
                {linkText}
              </Link>
            );
          })}
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.3 },
          }}
          className="col-start-2 md:col-start-10 col-end-14 flex flex-col h-full justify-between gap-2"
        >
          {rightLinks.map(({ linkText, page, path }, i) => {
            const pathLink =
              (page && `/${page.slug.current}`) || (path && `/${path}`);
            return (
              <Link
                key={i}
                to={pathLink}
                className={cx(
                  "text-white text-lg md:text-xl block leading-none",
                  {
                    "mb-4": i !== rightLinks.length - 1,
                  }
                )}
              >
                {linkText}
              </Link>
            );
          })}
        </motion.div>
      </div>

      <motion.div className="md:hidden col-start-1 col-end-15 absolute bottom-0 flex flex-row items-center justify-center w-full text-lg text-white border-t">
        <ExternalLink
          link="https://castlepointfunds.formsbyair.com/forms/application"
          linkText="Invest Now"
          className="w-full border-r py-4"
        />
        <ExternalLink
          link="https://castlepointfundsapi.mmcnz.co.nz/User/LogOn"
          linkText="Login"
          className="w-full py-4"
        />
      </motion.div>
    </motion.div>
  );
};

export const NavModal = ({ navOpen, links }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {navOpen && <NavInner key={navOpen} links={links} />}
    </AnimatePresence>
  );
};
