import React from 'react';
import { motion } from 'framer-motion';
import { useLocation } from '@reach/router';
import { Formik, Form, Field, ErrorMessage } from 'formik';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const SubscribeForm = ({ heading }) => {
  const { pathname } = useLocation();

  return (
    <div className='md:px-gutter mx-auto md:py-28 max-w-6xl 2xl:px-0'>
      <h2 className='text-white text-center mb-8 md:mb-16 blockH2'>
        Stay updated with Castle Point Funds.
      </h2>
      <Formik
        initialValues={{
          email: '',
        }}
        validate={(values) => {
          const errors = {};

          if (!values.email) {
            errors.email = 'Email address is Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          fetch(pathname || '/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': `subscription-form`,
              ...values,
            }),
          })
            .then(() => {
              setSubmitting(false);
              setStatus('success');
            })
            .catch((error) => alert(error));
        }}
      >
        {({
          onChange,
          isSubmitting,
          status,
          values,
          /* and other goodies */
        }) => (
          <Form
            className='md:px-gutter 2xl:px-0'
            name='subscription-form'
            data-netlify='true'
            data-netlify-honeypot='bot-field'
            subject='Subscription email'
          >
            {/* Hidden Fields for netlify */}
            <input type='hidden' name='form-name' value='subscription-form' />
            <p hidden>
              <label>
                <input name='bot-field' onChange={onChange} />
              </label>
            </p>
            {status === 'success' ? (
              <div className='col-span-full text-left'>
                <motion.h2
                  className='font-display text-2xl text-center text-white'
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                >
                  Thank you, we will add you to our mailing list and be in touch
                  soon
                </motion.h2>
              </div>
            ) : (
              <>
                <div className='grid grid-cols-2 grid-rows-1 text-xs mx-auto md:text-lg lg:px-gutter 2xl:px-64'>
                  <div className='col-start-1 col-span-2 row-start-1 subscribe-form'>
                    <Field
                      type='email'
                      name='email'
                      className='border border-teal w-full py-4 px-8 placeholder-current rounded-full focus:outline-none text-white bg-dark-navy'
                      placeholder='Email Address'
                    />
                    <ErrorMessage
                      name='email'
                      className='text-sm my-2 text-white'
                      component='div'
                    />
                  </div>
                  <button
                    className='col-start-2 row-start-1 self-start justify-self-end focus:outline-none bg-teal text-white px-8 py-4 rounded-full font-bold hover:opacity-75'
                    style={{ transform: 'translateY(1px)' }}
                    type='submit'
                  >
                    Subscribe
                  </button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
