import { graphql, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';
import { useState } from 'react';
import { usePapaParse } from 'react-papaparse';

export const useSiteConfig = () => {
  const { sanitySiteConfig } = useStaticQuery(graphql`
    query {
      sanitySiteConfig {
        mainNav {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
          ... on SanityNavLinks {
            heading
            links {
              ... on SanityPathLink {
                _type
                ...PathLinkFields
              }
              ... on SanityPageLink {
                _type
                ...PageLinkFields
              }
            }
          }
          ... on SanityExternalLink {
            _key
            _type
            link
            linkText
          }
        }
        dropdownNavLeft {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
        }
        dropdownNavRight {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
        }
        privacyPolicy {
          heading
          content: _rawContent(resolveReferences: { maxDepth: 100 })
          bg
          meta {
            _key
            _type
            metaTitle
            metaDescription
            _rawMetaImage
          }
        }
        csvUpload {
          Ranger: _rawRanger(resolveReferences: { maxDepth: 100 })
          Oceans: _rawOceans(resolveReferences: { maxDepth: 100 })
          TransTasman: _rawTransTasman(resolveReferences: { maxDepth: 100 })
        }
        chart {
          text: _rawHeading(resolveReferences: { maxDepth: 100 })
          lefttab
          righttab
          blockConfig {
            ...BlockSettings
          }
          showRanger
          showOceans
          showTasman
        }
        footerlinks {
          heading
          pageLinks {
            ... on SanityExternalLink {
              _key
              _type
              link
              linkText
            }
            ... on SanityPageLink {
              _key
              _type
              ...PageLinkFields
              linkText
            }
          }
        }
        info: _rawFooterinfo(resolveReferences: { maxDepth: 100 })
        flogo: _rawFooterLogo(resolveReferences: { maxDepth: 100 })
        nlogo: _rawNavbarlogo(resolveReferences: { maxDepth: 100 })
        nlogodark: _rawNavbarlogodark(resolveReferences: { maxDepth: 100 })
        percentagesCSV {
          Ranger: _rawRanger(resolveReferences: { maxDepth: 100 })
          Oceans: _rawOceans(resolveReferences: { maxDepth: 100 })
          TransTasman: _rawTransTasman(resolveReferences: { maxDepth: 100 })
        }
        rangerPercentages {
          unitPrice
          date
        }
        oceansPercentages {
          unitPrice
          date
        }
        tasmanPercentages {
          unitPrice
          date
        }
      }
    }
  `);
  const { readRemoteFile } = usePapaParse();
  const [rangerPercentages, setRangerPercentages] = useState([]);
  const [oceansPercentages, setOceansPercentages] = useState([]);
  const [tasmanPercentages, setTasmanPercentages] = useState([]);

  const handleReadRemoteFile = (url, setFund) => {
    readRemoteFile(url, {
      complete: (results) => {
        const data = convertCsvToJson(results.data);
        setFund(data);
      },
    });
  };

  const ranger = sanitySiteConfig.percentagesCSV.Ranger;
  const oceans = sanitySiteConfig.percentagesCSV.Oceans;
  const tasman = sanitySiteConfig.percentagesCSV.TransTasman;

  const rangerEntries = Object.entries(ranger);
  const oceansEntries = Object.entries(oceans);
  const tasmanEntries = Object.entries(tasman);

  useEffect(() => {
    handleReadRemoteFile(rangerEntries[1][1].url, setRangerPercentages);
    handleReadRemoteFile(oceansEntries[1][1].url, setOceansPercentages);
    handleReadRemoteFile(tasmanEntries[1][1].url, setTasmanPercentages);
  }, []);

  const result = {
    ...sanitySiteConfig,
    rangerPercentages: {
      date: sanitySiteConfig.rangerPercentages?.date,
      unitPrice: sanitySiteConfig.rangerPercentages?.unitPrice,
      primaryFund: rangerPercentages[0],
      ocr: rangerPercentages[1],
      outPerformance: rangerPercentages[2],
    },
    oceansPercentages: {
      date: sanitySiteConfig.oceansPercentages?.date,
      unitPrice: sanitySiteConfig.oceansPercentages?.unitPrice,
      primaryFund: oceansPercentages[0],
      ocr: oceansPercentages[1],
      outPerformance: oceansPercentages[2],
    },
    tasmanPercentages: {
      date: sanitySiteConfig.tasmanPercentages?.date,
      unitPrice: sanitySiteConfig.tasmanPercentages?.unitPrice,
      primaryFund: tasmanPercentages[0],
      ocr: tasmanPercentages[1],
      outPerformance: tasmanPercentages[2],
    },
  };

  return result || {};
};

const convertCsvToJson = (data) => {
  const [headings, ...rest] = data;

  return rest.map((row) =>
    row.reduce((obj, item, i) => {
      obj[headings[i]] = item;
      return obj;
    }, {})
  );
};
