import React, { useState } from 'react';
import { useSiteConfig } from '../../../GraphQl/useSiteConfig';

function getPerformanceData(index, data) {
  const keys = [
    '1 month',
    '3 months',
    '6 months',
    '12 months',
    '3 years',
    '5 years',
    'Since Inception (p.a.)',
  ];
  const primaryFund = keys.map((key) => data[index].primaryFund?.[key]);
  const ocr = keys.map((key) => data[index].ocr?.[key]);
  const outPerformance = keys.map((key) => data[index].outPerformance?.[key]);

  return { primaryFund, ocr, outPerformance };
}

function PerformanceTable(props) {
  const { rangerPercentages, oceansPercentages, tasmanPercentages } =
    useSiteConfig();
  const percentages = [rangerPercentages, oceansPercentages, tasmanPercentages];
  const { primaryFund, ocr, outPerformance } = getPerformanceData(
    props.i,
    percentages
  );

  return (
    <>
      <section
        className={`${
          props.i === 0 && (props.showRanger ? 'flex flex-col' : 'hidden')
        } ${props.i === 1 && (props.showOceans ? 'flex flex-col' : 'hidden')} ${
          props.i === 2 && (props.showTasman ? 'flex flex-col' : 'hidden')
        } py-5 flex-row justify-between items-center ${
          props.color || 'text-dark-navy'
        }`}
      >
        <PerformanceData
          title={props.content.fund}
          data={primaryFund}
          color={props.color}
        />
        <PerformanceData
          title={percentages.map((percentage) => percentage.ocr?.Fund)[props.i]}
          data={ocr}
          color={props.color}
        />
        <PerformanceData
          title={
            percentages.map((percentage) => percentage.outPerformance?.Fund)[
              props.i
            ]
          }
          data={outPerformance}
          color={props.color}
        />
      </section>
    </>
  );
}

function PerformanceData({ title, data, color }) {
  return (
    <div className='w-full'>
      <div className='text-base font-bold'>{title}</div>
      <div
        className={`py-4 grid grid-cols-12 gap-4  w-full ${
          color || 'text-dark-navy'
        }`}
      >
        <div className='col-span-6 text-xs md:text-base'>
          {[
            '1 month',
            '3 months',
            '6 months',
            '1 year',
            '3 years (p.a)',
            '5 years (p.a)',
            'Since inception (p.a)',
          ].map((period, index) => (
            <p key={index}>{period}</p>
          ))}
        </div>
        <div className='col-span-6 text-xs md:text-base'>
          {data.map((value, index) => (
            <p key={index}>{value}</p>
          ))}
        </div>
      </div>
    </div>
  );
}

function DataDropdowns(props) {
  const [expanded, setExpanded] = useState(false);
  const { rangerPercentages, oceansPercentages, tasmanPercentages } =
    useSiteConfig();
  const dropdowns = [
    { fund: 'Ranger Fund', unitPrice: rangerPercentages.unitPrice },
    { fund: '5 Oceans Fund', unitPrice: oceansPercentages.unitPrice },
    { fund: 'Trans-Tasman Fund', unitPrice: tasmanPercentages.unitPrice },
  ];

  return (
    <div className='grid grid-cols-14'>
      <div className='col-start-1 col-end-15'>
        {dropdowns.map((tab, i) => (
          <PerformanceTable
            key={`FundTable-${i}`}
            i={i}
            expanded={expanded}
            setExpanded={setExpanded}
            content={tab}
            color={props.color}
            showRanger={props.showRanger}
            showOceans={props.showOceans}
            showTasman={props.showTasman}
          />
        ))}
      </div>
    </div>
  );
}

export default DataDropdowns;
