import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import React, { useState } from 'react';
import { useSiteConfig } from '../../GraphQl/useSiteConfig';
import CarrotDown from '../../images/carrotdown.svg';
import { PortableTextBlock } from '../sanity/portableTextBlock';
import { ExternalLink } from '../ui';
import { SubscribeForm } from './subscribeForm';

const Chevron = ({ isOpen }) => {
  const chevronVariants = {
    closed: {
      rotate: 0,
      transition: {
        duration: 0.3,
      },
    },
    opened: {
      rotate: 180,
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <motion.div
      variants={chevronVariants}
      initial='closed'
      animate={isOpen ? 'opened' : 'closed'}
    >
      <CarrotDown />
    </motion.div>
  );
};

const FooterLinks = (pageLinks, isWhiteText) => {
  return pageLinks.links.map((link, index) => {
    const className = cx('block mt-3 hover:opacity-75', {
      'text-white': isWhiteText,
    });
    return link._type === 'externalLink' ? (
      <ExternalLink
        key={index}
        link={link.link}
        linkText={link.linkText}
        className={className}
      />
    ) : (
      <Link
        key={index}
        to={`/${link.page?.slug?.current}`}
        className={className}
      >
        {link.linkText}
      </Link>
    );
  });
};

const Accordion = ({
  index,
  expanded,
  setExpanded,
  content,
  setDropdownOpen,
}) => {
  const isOpen = index === expanded;
  return (
    <>
      <motion.div
        className='py-5 border-b border-teal flex flex-row justify-between items-center text-white'
        initial={false}
        onClick={() => setExpanded(isOpen ? false : index)}
      >
        <p>{content.heading}</p>
        <Chevron isOpen={isOpen} />
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <motion.div
              variants={{
                collapsed: { scale: 1, opacity: 0 },
                open: { scale: 1, opacity: 1 },
              }}
              transition={{ duration: 0.5 }}
              className='text-left pt-4'
            >
              <FooterLinks links={content.pageLinks} isWhiteText />
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export const Footer = ({ setDropdownOpen }) => {
  const { flogo, info, footerlinks } = useSiteConfig();
  const [expanded, setExpanded] = useState(false);

  return (
    <div className='bg-dark-navy flex justify-center items-center z-2000 w-full py-12'>
      <div className='grid grid-cols-14 w-full text-center lg:text-left'>
        <div className='col-start-2 col-end-14'>
          <SubscribeForm />

          <hr className='text-teal mt-16 md:mt-0' />
          <div className='lg:hidden'>
            {footerlinks.map((row, index) => (
              <Accordion
                key={index}
                index={index}
                expanded={expanded}
                setExpanded={setExpanded}
                content={row}
                setDropdownOpen={setDropdownOpen}
              />
            ))}
          </div>
        </div>
        <div className='col-start-2 col-end-14 lg:col-end-4 flex flex-col lg:my-12 mt-12 mb-6'>
          <Link to='/'>
            <Image className='w-16 mx-auto lg:mx-0' {...flogo} />
          </Link>
        </div>
        <div className='col-start-2 col-end-14 lg:col-end-5 text-white'>
          <p className='md:font-bold mb-4 blockH7 font-bold'>{info.heading}</p>
          <PortableTextBlock text={info.links} />
        </div>
        <div className='col-start-2 lg:col-start-8 col-end-14 text-white flex-row gap-5 hidden lg:flex'>
          {footerlinks?.map((linkColumn, index) => {
            const externalLink = linkColumn.pageLinks._type === 'externalLink';
            return (
              <div key={index} className='w-full'>
                <p className='font-bold mb-4 blockH8'>{linkColumn.heading}</p>
                <FooterLinks links={linkColumn.pageLinks} />

                {index === 0 && (
                  <ExternalLink
                    link='https://castlepointfunds.formsbyair.com/forms/application'
                    linkText={'Invest Now'}
                    className='bg-gold rounded-full text-dark-navy blockH8 font-bold py-3 px-6 mt-3 inline-block'
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className='col-start-4 md:col-start-2 col-end-12 md:col-end-14 text-teal blockH10 font-bold flex justify-between items-center mt-12 flex-col-reverse lg:flex-row'>
          <p className='mt-4 lg:mt-0'>
            {new Date().getFullYear()} Castle Point Funds, Inc. All rights
            reserved.
          </p>
          <Link to='/privacy-policy'>Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
};
