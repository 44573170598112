import React from "react";
import AnchorScroll from "react-anchor-link-smooth-scroll";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
import cx from "classnames";
import { useSanityPath } from "../../hooks";
import { useAtom } from "jotai";
import { pathNameAtom } from "../../lib/jotai";

export const PageLink = ({ page, linkText, className, ...rest }) => {
  const [, setPathName] = useAtom(pathNameAtom);
  const path = useSanityPath(page);

  const handleClick = () => {
    if (path === "/documents") {
      const currentPath = window.location.pathname;
      setPathName(currentPath);
    } else return null;
  };

  return (
    <Link
      to={path}
      onClick={handleClick}
      className={cx(className, "hover:opacity-75")}
      {...rest}
    >
      {linkText}
    </Link>
  );
};

export const PathLink = ({ path, linkText, className, ...rest }) => {
  return (
    <Link
      to={`/${path}`}
      className={cx(className, "hover:opacity-75")}
      {...rest}
    >
      {linkText}
    </Link>
  );
};

export const PageAnchorLink = ({
  anchor,
  page,
  linkText,
  className,
  ...rest
}) => {
  const path = useSanityPath(page);
  return (
    <AnchorLink
      to={`/${path}#${anchor.current}`}
      className={cx(className, "hover:opacity-75")}
      {...rest}
    >
      {linkText}
    </AnchorLink>
  );
};

export const AnchorScrollLink = ({ anchor, linkText, className, ...rest }) => {
  return (
    <AnchorScroll
      href={`#${anchor.current}`}
      className={cx(className, "hover:opacity-75")}
      {...rest}
    >
      {linkText}
    </AnchorScroll>
  );
};

export const ExternalLink = ({ linkText, link, className, ...rest }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={cx(className, "hover:opacity-75")}
      {...rest}
    >
      {linkText}
    </a>
  );
};
