import cx from "classnames";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import React, { useState } from "react";
import useFundData from "../../../hooks/useFundData";
import { PortableTextBlock } from "../../sanity/portableTextBlock";
import { BlockWrapper } from "../blockWrapper";
import { Data } from "./data.js";
import DataDropdowns from "./dataDropdowns";

const Chart = ({
  blockConfig,
  text,
  lefttab,
  righttab,
  color,
  showRanger,
  showTasman,
  showOceans,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [lefttab, righttab];
  const { fundData } = useFundData();

  return (
    <BlockWrapper {...blockConfig} className="grid grid-cols-14 bg-slate/5">
      <div className="col-start-2 col-end-14 flex flex-col lg:flex-row w-full text-center lg:text-left">
        <div className="w-full">
          <PortableTextBlock
            text={text}
            className={`prose-basechart ${
              (color && "[&>*]:text-white") || "text-teal"
            }`}
          />
        </div>
        <div className="w-full h-full hidden lg:flex justify-end items-end">
          <div
            className={`${
              color ? "bg-dark-navy/25" : "bg-slate/10"
            } rounded-full`}
          >
            <AnimateSharedLayout>
              {tabs.map((tab, i) => (
                <div
                  key={i}
                  className="relative text-white inline w-full h-full py-4 px-8"
                >
                  <motion.button
                    key={i}
                    className={cx(
                      "focus:outline-none font-bold relative z-20 py-4",
                      {
                        "text-teal": activeTab !== i,
                        [color]: activeTab !== i && color,
                      }
                    )}
                    onClick={() => setActiveTab(i)}
                  >
                    {tab}
                  </motion.button>
                  {activeTab === i && (
                    <motion.div
                      layoutId="button"
                      className={cx(
                        `w-full h-full  rounded-full absolute -top-0.5 left-0 z-10 p-7`,
                        {
                          "bg-teal": !color,
                          "bg-dark-navy/50": color,
                        }
                      )}
                    />
                  )}
                </div>
              ))}
            </AnimateSharedLayout>
          </div>
        </div>
      </div>

      <div className="col-start-2 col-end-14 hidden lg:block">
        <AnimatePresence exitBeforeEnter>
          <Data
            key={activeTab}
            activeTab={activeTab}
            {...fundData}
            color={color}
            showRanger={showRanger}
            showOceans={showOceans}
            showTasman={showTasman}
          />
        </AnimatePresence>
      </div>

      <div className="lg:hidden col-start-2 col-end-14 mt-6">
        <AnimatePresence exitBeforeEnter>
          <DataDropdowns
            color={color}
            showRanger={showRanger}
            showOceans={showOceans}
            showTasman={showTasman}
          />
        </AnimatePresence>
      </div>
    </BlockWrapper>
  );
};

export default Chart;
