import cx from "classnames";
import React from "react";

export const MenuToggle = ({ className, navOpen, onClick, colour }) => (
  <button
    onClick={onClick}
    className={cx("tham tham-e-squeeze tham-w-6", className, {
      "tham-active": navOpen,
    })}
  >
    <div className="tham-box">
      <div className={`tham-inner ${colour ?? "bg-white"}`} />
    </div>
  </button>
);
