import React from "react";
import PortableText from "react-portable-text";
import { ImageModule, VideoModule } from "../modules";
import {
  AnchorScrollLink,
  ExternalLink,
  PageAnchorLink,
  PageLink,
  PathLink,
} from "../ui/links";

const serializers = {
  h1: (props) => <h1 className="blockH1" {...props} />,
  h2: (props) => <h2 className="blockH2" {...props} />,
  h3: (props) => <h3 className="blockH3" {...props} />,
  h4: (props) => <h4 className="blockH4" {...props} />,
  h5: (props) => <h5 className="blockH5" {...props} />,
  h6: (props) => <h6 className="blockH6" {...props} />,
  "super-script": (props) => <sup>{props.children}</sup>,
  "sub-script": (props) => <sub>{props.children}</sub>,
  link: (props) => (
    <a {...props} className="text-teal blockH7 font-bold" target="_blank">
      {props.children}
    </a>
  ),

  pageLink: (props) => (
    <PageLink {...props} className="font-bold text-teal blockH7" />
  ),
  pathLink: (props) => <PathLink {...props} className="text-teal blockH7" />,
  anchorLink: (props) => <AnchorScrollLink {...props} />,
  externalLink: (props) => (
    <ExternalLink className="text-teal blockH7 font-bold" {...props} />
  ),
  pageAnchorLink: (props) => <PageAnchorLink {...props} />,
  normal: (props) => <p className="blockH7 pt-4" {...props} />,
  strong: (props) => <strong className="font-bold" {...props} />,
  imageModule: (props) => <ImageModule {...props} />,
  videoModule: (props) => <VideoModule {...props} />,
  highlight: (props) => <span {...props} className="text-teal" />,
  ul: (props) => (
    <ul className="blockH7 list-disc list-outside ml-6" {...props} />
  ),
  ol: (props) => (
    <ol className="blockH7 list-decimal list-outside ml-6" {...props} />
  ),
  small: (props) => <p className="blockH9 pt-4" {...props} />,
  disclaimer: (props) => <p className="blockH10 pt-4" {...props} />,
};

export const PortableTextBlock = ({ text, ...props }) => {
  return text ? (
    <PortableText content={text} serializers={serializers} {...props} />
  ) : null;
};
