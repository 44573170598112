export const usePadTop = (size) => {
  switch (size) {
    case "small":
      return "pt-12 md:pt-24";
    case "medium":
      return "pt-16 md:pt-36";
    case "large":
      return "pt-24 lg:pt-64";
    default:
      return "";
  }
};
