import { useEffect, useState } from "react";
import { usePapaParse } from "react-papaparse";
import { useSiteConfig } from "../GraphQl/useSiteConfig";

export default function useFundData() {
  const { readRemoteFile } = usePapaParse();
  const { csvUpload } = useSiteConfig();
  const [fundData, setFundData] = useState({});

  const entries = Object.entries(csvUpload);

  const handleReadRemoteFile = (fund, url) => {
    readRemoteFile(url, {
      complete: (results) => {
        const data = convertCsvToJson(
          results.data,
          fundDetails[fund].name,
          fundDetails[fund].ocr
        );
        setFundData((prev) => ({ ...prev, [fund]: data }));
      },
    });
  };

  useEffect(() => {
    entries.forEach((entry) => {
      const [fund, file] = entry;
      if (!file) return;
      handleReadRemoteFile(fund, file.asset.url);
    });
  }, []);

  return { fundData };
}

const convertCsvToJson = (data, fundName, ocrName) => {
  return data.map((row) => {
    const date = row[0];
    const fund = row[1];
    const ocr = row[2];

    return { Date: date, [fundName]: fund, [ocrName]: ocr };
  });
};

const fundDetails = {
  Ranger: {
    name: "Ranger Fund",
    ocr: "OCR + 5%",
  },
  Oceans: {
    name: "5 Oceans Fund",
    ocr: "OCR + 3%",
  },
  TransTasman: {
    name: "Trans-Tasman",
    ocr: "S&P/NZX 50 Index",
  },
};
