export const usePadBottom = (size) => {
  switch (size) {
    case "small":
      return "pb-12 md:pb-24";
    case "medium":
      return "pb-16 md:pb-36";
    case "large":
      return "pb-24 md:pb-48";
    default:
      return "";
  }
};
