import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import React, { useState } from 'react';
import Headroom from 'react-headroom';
import { useSiteConfig } from '../../GraphQl/useSiteConfig';
import CarrotDown from '../../images/carrotdown.svg';
import { ExternalLink } from '../ui';
import { Dropdown } from './dropdown';
import { MenuToggle } from './menuToggle';
import { NavLink } from './navLink';
import { NavModal } from './navModal';

const headerVariants = {
  open: {
    height: '250px',
  },
  closed: {
    height: 'auto',
  },
};

export const Header = ({ headerReversed, dropdownOpen, setDropdownOpen }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [maximiseHeader, setMaximiseHeader] = useState(false);

  const toggleNav = () => setNavOpen((prev) => !prev);

  const {
    mainNav,
    nlogo,
    nlogodark,
    dropdownNavLeft,
    dropdownNavRight,
    chart,
  } = useSiteConfig();

  const currentLogo =
    (headerReversed && !isPinned) || navOpen || maximiseHeader
      ? { ...nlogo }
      : { ...nlogodark };

  const getHeaderClassName = () => {
    if (maximiseHeader) return 'bg-dark-navy text-white';

    if ((isPinned || !headerReversed) && !navOpen)
      return 'bg-white text-dark-navy';

    return 'text-white';
  };

  return (
    <>
      <Headroom
        style={{ zIndex: '50' }}
        onPin={() => setIsPinned(true)}
        onUnpin={() => setIsPinned(false)}
        onUnfix={() => setIsPinned(false)}
      >
        <header
          className={cx(
            'z-50 w-full transition-all duration-300',
            getHeaderClassName()
          )}
        >
          {/* remove Performance & Prices until further notice */}
          {/* <button
            className="bg-teal text-white w-full text-center font-black flex flex-row items-center justify-center py-1 focus:outline-none blockH8 font-bold"
            onClick={() => {
              setNavOpen(false);
              setDropdownOpen(true);
            }}
          >
            Performance & Prices
            <CarrotDown className="ml-2 w-3" />
          </button> */}
          <motion.div
            variants={headerVariants}
            initial='closed'
            animate={maximiseHeader ? 'open' : 'closed'}
            className='py-8'
            onMouseLeave={() => setMaximiseHeader(false)}
          >
            <nav className='flex justify-between items-center w-full px-gutter'>
              <div className='w-2/3 sm:w-1/4'>
                <Link to='/'>
                  <Image className='max-w-xs w-full' {...currentLogo} />
                </Link>
              </div>
              <motion.div className='flex flex-row gap-7 items-center font-bold blockH8 relative'>
                {mainNav.map((item, index) => {
                  const isCtaButton = index > mainNav.length - 3; // last two items
                  const linkStyles = cx('hidden', {
                    '2xl:block': !isCtaButton,
                    'border rounded-full py-[0.625rem] px-6 md:block bg-gold border-gold text-dark-navy':
                      isCtaButton,
                  });

                  if (!item.links) {
                    if (item._type === 'externalLink') {
                      return (
                        <ExternalLink
                          link={item.link}
                          key={index}
                          linkText={item.linkText}
                          className={cx('border-current', linkStyles)}
                        />
                      );
                    }
                    return (
                      <NavLink
                        key={index}
                        {...item}
                        className={cx('border-current', linkStyles)}
                      />
                    );
                  }

                  return (
                    <div
                      key={index}
                      onMouseEnter={() => setMaximiseHeader(true)}
                      className={cx('cursor-default', linkStyles)}
                    >
                      <NavLink
                        link
                        {...item.links[0]}
                        linkText={item.heading}
                        className={cx({
                          'text-teal underline decoration-teal underline-offset-[6px] decoration-2':
                            maximiseHeader,
                        })}
                      />
                      <div
                        className={cx(
                          'flex flex-col absolute top-12 gap-2 pb-8',
                          { 'sr-only': !maximiseHeader }
                        )}
                      >
                        {item.links.map((link) => {
                          return <NavLink {...link} className='font-light' />;
                        })}
                      </div>
                    </div>
                  );
                })}
                <MenuToggle
                  className={'2xl:hidden'}
                  navOpen={navOpen}
                  onClick={toggleNav}
                  colour={
                    (isPinned || !headerReversed) && !navOpen
                      ? 'bg-teal'
                      : 'bg-white'
                  }
                />
              </motion.div>
            </nav>
          </motion.div>
        </header>
      </Headroom>

      <AnimatePresence exitBeforeEnter>
        <NavModal
          navOpen={navOpen}
          links={[dropdownNavLeft, dropdownNavRight]}
        />
      </AnimatePresence>
      {/* <Dropdown
        dropdownOpen={dropdownOpen}
        setDropdownOpen={setDropdownOpen}
        chart={chart}
      /> */}
    </>
  );
};
