import cx from "classnames";
import { motion } from "framer-motion";
import React, { useState } from "react";

import "../css/main.css";

import { Footer } from "../components/nav/footer";
import { Header } from "../components/nav/header";

const mainVars = {
  open: {
    opacity: 1,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.5,
      when: "afterChildren",
    },
  },
};

const Layout = ({ children, headerReversed = false }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <>
      <Header
        headerReversed={headerReversed}
        dropdownOpen={dropdownOpen}
        setDropdownOpen={setDropdownOpen}
      />
      <motion.main
        variants={mainVars}
        className={cx("w-full absolute top-0 left-0", {
          "pt-24 lg:pt-0": !headerReversed,
        })}
        initial="closed"
        animate="open"
        exit="closed"
      >
        {children}
        <Footer dropdownOpen={dropdownOpen} setDropdownOpen={setDropdownOpen} />
      </motion.main>
    </>
  );
};

export default Layout;
