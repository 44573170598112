import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { useSiteConfig } from '../../../GraphQl/useSiteConfig';
import { Graph } from './graphs';
import { SparkLine } from './sparkLine';

const vars = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const Accordion = ({
  i,
  expanded,
  setExpanded,
  data,
  color,
  showRanger,
  showOceans,
  showTasman,
}) => {
  const isOpen = i === expanded;

  const { rangerPercentages, oceansPercentages, tasmanPercentages } =
    useSiteConfig();

  const activeFund =
    i === 0
      ? rangerPercentages
      : i === 1
      ? oceansPercentages
      : tasmanPercentages;

  return (
    <>
      {/* Row 1 */}
      {activeFund.primaryFund && (
        <motion.div
          className={`${i === 0 && ((showRanger && 'grid') || 'hidden')} ${
            i === 1 && ((showOceans && 'grid') || 'hidden')
          } ${
            i === 2 && ((showTasman && 'grid') || 'hidden')
          } grid-cols-11 text-center leading-6 justify-center items-start ${
            (color && color) || 'text-teal'
          }`}
        >
          <p className='col-start-1 col-end-3 text-left font-bold min-h-[60px]'>
            {activeFund.primaryFund['Fund']}
          </p>
          {/* <button
            onClick={() => setExpanded(isOpen ? false : i)}
            className='mx-auto col-start-3 col-end-5 focus:outline-none'
          >
            <SparkLine color={color} i={i} />
          </button> */}
          <p>{activeFund.primaryFund['1 month']}</p>
          <p>{activeFund.primaryFund['3 months']}</p>
          <p>{activeFund.primaryFund['6 months']}</p>
          <p>{activeFund.primaryFund['12 months']}</p>
          <p>{activeFund.primaryFund['3 years']}</p>
          <p>{activeFund.primaryFund['5 years']}</p>
          <p>{activeFund.primaryFund['Since Inception (p.a.)']}</p>
        </motion.div>
      )}

      {/* Row 2 */}
      {activeFund.ocr && (
        <motion.div
          className={`${i === 0 && ((showRanger && 'grid') || 'hidden')} ${
            i === 1 && ((showOceans && 'grid') || 'hidden')
          } ${
            i === 2 && ((showTasman && 'grid') || 'hidden')
          } grid-cols-11 text-center leading-6 justify-center items-start ${
            (color && color) || 'text-teal'
          }`}
        >
          <p className='col-start-1 col-end-3 text-left font-bold min-h-[60px]'>
            {activeFund.ocr['Fund']}
          </p>
          {/* <div className='min-h-[60px] mx-auto col-start-3 col-end-5'></div> */}
          <p>{activeFund.ocr['1 month']}</p>
          <p>{activeFund.ocr['3 months']}</p>
          <p>{activeFund.ocr['6 months']}</p>
          <p>{activeFund.ocr['12 months']}</p>
          <p>{activeFund.ocr['3 years']}</p>
          <p>{activeFund.ocr['5 years']}</p>
          <p>{activeFund.ocr['Since Inception (p.a.)']}</p>
        </motion.div>
      )}

      {/* Row 3 */}
      {activeFund.outPerformance && (
        <motion.div
          className={`${i === 0 && ((showRanger && 'grid') || 'hidden')} ${
            i === 1 && ((showOceans && 'grid') || 'hidden')
          } ${
            i === 2 && ((showTasman && 'grid') || 'hidden')
          } grid-cols-11 text-center leading-6 justify-center items-start ${
            (color && color) || 'text-teal'
          }`}
        >
          <p className='col-start-1 col-end-3 text-left font-bold min-h-[60px]'>
            {activeFund.outPerformance['Fund']}
          </p>
          {/* <div className='min-h-[60px] mx-auto col-start-3 col-end-5'></div> */}
          <p>{activeFund.outPerformance['1 month']}</p>
          <p>{activeFund.outPerformance['3 months']}</p>
          <p>{activeFund.outPerformance['6 months']}</p>
          <p>{activeFund.outPerformance['12 months']}</p>
          <p>{activeFund.outPerformance['3 years']}</p>
          <p>{activeFund.outPerformance['5 years']}</p>
          <p>{activeFund.outPerformance['Since Inception (p.a.)']}</p>
        </motion.div>
      )}

      <AnimatePresence>
        {isOpen && (
          <motion.section
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <motion.div
              variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
              transition={{ duration: 0.8 }}
              className='py-8'
            >
              {data?.data && <Graph data={data} i={i} color={color} />}
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export const Data = ({
  Ranger,
  TransTasman,
  Oceans,
  activeTab,
  color,
  showRanger,
  showOceans,
  showTasman,
}) => {
  const [expanded, setExpanded] = useState(showRanger ? 0 : showOceans ? 1 : 2);

  const { rangerPercentages, oceansPercentages, tasmanPercentages } =
    useSiteConfig();

  const financialData = [
    {
      title: 'Ranger Fund',
      data: Ranger,
      unitPrice: rangerPercentages?.unitPrice,
      date: rangerPercentages?.date,
    },
    {
      title: '5 Oceans Fund',
      data: Oceans,
      unitPrice: oceansPercentages?.unitPrice,
      date: oceansPercentages?.date,
    },
    {
      title: 'Trans-Tasman Fund',
      data: TransTasman,
      unitPrice: tasmanPercentages?.unitPrice,
      date: tasmanPercentages?.date,
    },
  ];

  switch (activeTab) {
    case 1:
      return (
        <motion.div
          className='mt-20'
          variants={vars}
          initial='hidden'
          animate='visible'
          exit='hidden'
        >
          <div
            className={`grid grid-cols-12 text-center text-base font-bold ${
              color ? color : 'text-teal'
            } leading-6 justify-center items-end border-b ${
              !color && 'border-teal'
            } pb-8`}
          >
            <p className='col-start-1 col-end-3 text-left'>Fund</p>
            <p className='col-start-8 col-end-9'>Unit Price</p>
            <p className='col-start-11 col-end-12'>Date</p>
          </div>

          <div
            className={`grid grid-cols-12 text-center text-base font-bold ${
              (color && color) || 'text-teal'
            }`}
          >
            {financialData.map(({ title, unitPrice, date }, i) => (
              <React.Fragment key={i}>
                <p
                  className={`col-start-1 col-end-3 text-left mt-8 ${
                    i === 0 && ((showRanger && 'block') || 'hidden')
                  } ${i === 1 && ((showOceans && 'block') || 'hidden')} ${
                    i === 2 && ((showTasman && 'block') || 'hidden')
                  }`}
                >
                  {title}
                </p>
                <p
                  className={`col-start-8 col-end-9 mt-8 ${
                    i === 0 && ((showRanger && 'block') || 'hidden')
                  } ${i === 1 && ((showOceans && 'block') || 'hidden')} ${
                    i === 2 && ((showTasman && 'block') || 'hidden')
                  }`}
                >
                  {unitPrice}
                </p>
                <p
                  className={`col-start-11 col-end-12 mt-8 ${
                    i === 0 && ((showRanger && 'block') || 'hidden')
                  } ${i === 1 && ((showOceans && 'block') || 'hidden')} ${
                    i === 2 && ((showTasman && 'block') || 'hidden')
                  }`}
                >
                  {date}
                </p>
              </React.Fragment>
            ))}
          </div>
        </motion.div>
      );

    default:
      return (
        <motion.div
          className='mt-20'
          variants={vars}
          initial='hidden'
          animate='visible'
          exit='hidden'
        >
          <div
            className={`grid grid-cols-12 text-center text-base font-bold ${
              (color && color) || 'text-dark-teal'
            }`}
          ></div>
          <div
            className={`grid grid-cols-11 text-center font-bold ${
              (color && color) || 'text-teal'
            } leading-6 justify-center items-end border-b ${
              color ? '' : 'border-teal'
            } pb-8 mb-4`}
          >
            <p className='col-start-1 col-end-3 text-left'>Fund</p>
            {/* <p className='col-start-3 col-end-5'>Growth</p> */}
            <p>1 month</p>
            <p>3 months</p>
            <p>6 months</p>
            <p>1 year</p>
            <p>
              3 years <br />
              (p.a)
            </p>
            <p>
              5 years <br />
              (p.a)
            </p>
            <p>Since inception (p.a)</p>
          </div>

          {financialData.map((row, i) => {
            return (
              <Accordion
                key={i}
                i={i}
                expanded={expanded}
                setExpanded={setExpanded}
                data={row}
                color={color}
                showRanger={showRanger}
                showOceans={showOceans}
                showTasman={showTasman}
              />
            );
          })}
        </motion.div>
      );
  }
};
