import React from "react";
import cx from "classnames";

// Hooks
import { usePadTop, usePadBottom } from "../../hooks";

export const BlockWrapper = ({
  children,
  className,
  paddingTop,
  paddingBottom,
  slug,
  blockTheme,
}) => {
  const padTop = usePadTop(paddingTop);
  const padBottom = usePadBottom(paddingBottom);
  return (
    <section
      className={cx(`${className} ${padTop} ${padBottom}`, {
        "bg-white": blockTheme === "light",
      })}
      id={slug && slug.current}
    >
      {children}
    </section>
  );
};
